.Editor {
  height: 100%;
}

.Button {
  background: hsla(0, 0%, 0%, 0.05);
  padding: 5px;
  border: 1px solid #ddd;
  font-family: monospace;
  color: #999;
}

button.black {
  color: white;
  background: black;
  border-radius: 0.2rem;
  padding: 0.5rem 1rem 0.5rem 1rem;
  border-width: 0px;
}

.RichEditor-controls {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 0.3rem;
  padding-top: 0.3rem;
}

.File-controls {
  display: flex;
  justify-content: space-between;
  flex: 1;
  flex-direction: row;
  align-items: center;
  gap: 0.5rem;
  padding-bottom: 1rem;
}
