.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  font-size: calc(px + 2vmin);
  padding: 0.5rem;
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.Wrapper {
  display: flex;
  flex-direction: row;
  height: 100%;
  width: 100%;
}

.Container {
  width: 100%;
  height: 100%;
}

.EditorWrapper {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin: 0.5rem;
  height: inherit;
}

.Column {
  width: 50%;
  max-width: 50%;
  max-height: inherit;
  padding: 1rem;
  margin: 0.5rem;
}

.Paper {
  height: 85vh;
  overflow-y: scroll;
}

.Column > div > div > section {
  text-align: left;
  padding: 0.5rem 1rem 0.5rem 1rem;
  border: 1px solid darkgrey;
}

.Sidebar {
  width: 250px;
  background-color: #383c45;
  display: flex;
  flex-direction: column;
  color: white;
}

.Editor {
  flex-direction: column;
  height: 100%;
}

.InputWrapper {
  display: flex;
  justify-content: space-between;
  flex: 1;
  flex-direction: row;
  align-items: center;
  padding-bottom: 1rem;
  gap: 2rem;
}

.InputWrapper > div,
input {
  font-size: 1rem;
  width: 100%;
  border: 0;
}

.Controls {
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  /* padding: 0rem 1rem 1rem 1rem; */
}
